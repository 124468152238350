/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../_metronic/helpers'

interface LayoutProps {
  promoContentVisible?: boolean
  signInOnRight?: boolean
}

/**
 * This AuthLayout uses two sections for laying out the components of a page.
 * On one side is the form for logging in, the other a banner for promotional material.
 */
const AuthLayout = ({signInOnRight, promoContentVisible}: LayoutProps) => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const SignInComp = (
    <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10'>
      <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
        <div className='w-lg-500px p-10'>
          <Outlet />
        </div>
      </div>

      {/* begin::Footer */}
      <div className='d-flex flex-center flex-wrap px-5'>
        {/* begin::Links */}
        <div className='d-flex fw-semibold text-primary fs-base'>
          <a href='#' className='px-5' target='_blank'>
            Terms
          </a>

          <a href='#' className='px-5' target='_blank'>
            Plans
          </a>

          <a href='#' className='px-5' target='_blank'>
            Contact Us
          </a>
        </div>
        {/* end::Links */}
      </div>
      {/* end::Footer */}
    </div>
  )
  const PromoContentComp = (
    <div
      className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center'
      style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
    >
      {/* begin::Content */}
      <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
        {/* begin::Logo */}
        <Link to='/' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
        </Link>
        {/* end::Logo */}

        {/* begin::Image */}
        <img
          className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
          src={toAbsoluteUrl('/media/misc/auth-screens.png')}
          alt=''
        />
        {/* end::Image */}

        {/* begin::Title */}
        <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
          Fast, Efficient and Productive
        </h1>
        {/* end::Title */}

        {/* begin::Text */}
        <div className='text-white fs-base text-center'>
          In this kind of post,{' '}
          <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
            the blogger
          </a>
          introduces a person they’ve interviewed <br /> and provides some background information
          about
          <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
            the interviewee
          </a>
          and their <br /> work following this is a transcript of the interview.
        </div>
        {/* end::Text */}
      </div>
      {/* end::Content */}
    </div>
  )

  const layoutOrder = []
  if (signInOnRight) {
    if (promoContentVisible) {
      layoutOrder.push(PromoContentComp)
    }
    layoutOrder.push(SignInComp)
  } else {
    layoutOrder.push(SignInComp)
    if (promoContentVisible) {
      layoutOrder.push(PromoContentComp)
    }
  }

  return <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>{layoutOrder}</div>
}

export {AuthLayout}

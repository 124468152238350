import fetch from 'cross-fetch'
import {ApolloClient, HttpLink, InMemoryCache, gql} from '@apollo/client/core'

export class HydraqlClient {
  private apolloClient: ApolloClient<any>
  constructor() {
    this.apolloClient = new ApolloClient({
      link: new HttpLink({
        headers: {},
        uri: 'https://gql.stackhydra.com/graphql',
        fetch,
      }),
      cache: new InMemoryCache(),
    })
  }
  async createAccount({email, fullName, accountName, password}) {
    const response = await this.apolloClient.mutate({
      variables: {
        email,
        fullName,
        accountName,
        password,
      },
      mutation: gql`
        mutation CreateAccount(
          $accountName: String
          $email: String
          $fullName: String
          $password: String
        ) {
          createAccount(name: $accountName, email: $email, fullName: $fullName, password: $password) {
            id
            email
            fullName
            name
          }
        }
      `,
    })
    console.log(JSON.stringify(response))
  }
}
